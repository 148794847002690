import React from "react";
import "./App.css";
import logo from "./assets/logo.png"; // Ensure to place your logo in the assets folder

const App = () => {
  return (
    <div className="coming-soon">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="spinning-logo" />
      </div>
      <h1 className="message">Coming Soon...</h1>
    </div>
  );
};

export default App;
